// extracted by mini-css-extract-plugin
export var bottomHolder = "bize-ulasin-module--bottomHolder--GLzk8";
export var breadcrumb = "bize-ulasin-module--breadcrumb--Ur63J";
export var buttonHolder = "bize-ulasin-module--buttonHolder--AOJ12";
export var checkBoxHolder = "bize-ulasin-module--checkBoxHolder--dtybo";
export var checkBoxesHolder = "bize-ulasin-module--checkBoxesHolder--m4YD4";
export var contentHolder = "bize-ulasin-module--contentHolder--oBVBk";
export var formItemHolder = "bize-ulasin-module--formItemHolder--a3B-z";
export var headHolder = "bize-ulasin-module--headHolder--ZBhBW";
export var holder = "bize-ulasin-module--holder--nGZDM";
export var paragraph = "bize-ulasin-module--paragraph--4SwJb";
export var spanItem = "bize-ulasin-module--spanItem--0Kwna";
export var terms = "bize-ulasin-module--terms--Q4Vl4";