import * as React from "react";
import { navigate, PageProps } from "gatsby";
import Layout from "../components/layout/layout";
import { Breadcrumb } from "../components/breadcrumb/breadcrumb";
import Container from "../components/container/container";
import * as styles from "./bize-ulasin.module.scss";
import classNames from "classnames";
import Heading from "../components/basic-components/heading/heading";
import { useForm } from "../hooks/useForm";
import Button from "../components/basic-components/button/button";
import { ButtonTypesEnum } from "../components/basic-components/button/types";
import {Helmet} from "react-helmet";

interface Contact {
  name: string;
  surname: string;
  isHealthMember: boolean;
  email: string;
  phone: string;
  note: string;
  terms: boolean;
  emailContact: boolean;
  phoneContact: boolean;
  smsContact: boolean;
}

async function postData(url = "", data = {}) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  });
  return response.json(); // parses JSON response into native JavaScript objects
}

const ContactPage: React.FC<PageProps> = ({ location }: PageProps) => {
  const submitEmail = (data: Contact) => {
    postData(location.origin + "/v1/contact/", data).then(() => {
      navigate("/mesaj-gonderildi/");
    });
  };
  const {
    handleSubmit,
    handleChange,
    data: contact,
    errors,
  } = useForm<Contact>({
    validations: {
      name: {
        required: {
          value: true,
          message: "error",
        },
      },
      surname: {
        required: {
          value: true,
          message: "error",
        },
      },
      email: {
        required: {
          value: true,
          message: "error",
        },
      },
      note: {
        required: {
          value: true,
          message: "error",
        },
      },
      terms: {
        required: {
          value: true,
          message: "error",
        },
      },
    },
    onSubmit: (data) => submitEmail(data),
    initialValues: { isHealthMember: true, terms: true, emailContact: true },
  });

  return (
    <Layout
      hideTips={true}
      seoConfig={{
        title: "Bize Ulaşın",
        description:
          "Bize ulaşmak için, lütfen linkte yer alan e-posta formunu kullanın. Kişisel sağlık sorularınız ile ilgili doktorunuza danışmanızı tavsiye ederiz.",
      }}
    >
      <Helmet>
        <script type="application/ld+json">
          {`
            {
                "@context": "https://schema.org",
                "@type": "BreadcrumbList",
                "itemListElement": [
                  {"@type": "ListItem","position": 1,"name": "Ana Sayfa","item": "https://www.bisolnatur.com.tr/"},
                  {"@type": "ListItem","position": 2,"name": "Bize Ulaşın","item": "https://www.bisolnatur.com.tr/bize-ulasin/"}
                ]
            }
          `}
        </script>
      </Helmet>
      <Breadcrumb
        pathname={location.pathname}
        noBg={true}
        className={styles.breadcrumb}
      />
      <Container fullWidth className={styles.holder}>
        <Container>
          <div className={classNames("flexbox", styles.contentHolder)}>
            <div className={styles.headHolder}>
              <Heading>Bize Ulaşın</Heading>
              <p>
                Bize ulaşmak için, lütfen aşağıdaki e-posta formunu kullanın.
                Mesajlarınızı bekliyoruz. Bu platform kişisel sağlık ile ilgili
                soru sormak, yan etki raporlamak ve/veya <b>Sanofi</b> grup
                şirketleri ürünleri hakkında ürün şikayetinde bulunmak, bu
                ürünlere ilişkin teknik veya tıbbi soru sormak amacıyla{" "}
                <span className="bold underline purple">tasarlanmamıştır</span>.
                Benzeri bir durum ile karşılaştığınız hallerde, doktorunuza veya
                eczacınıza danışmanızı tavsiye ederiz. <br /> <br />
                Bu sayfa yan etki bildirimleri raporlamak amacıyla{" "}
                <span className="bold underline purple">
                  tasarlanmamıştır
                </span>{" "}
                ancak yan etki raporlamanız durumunda, <b>Opella </b> yasal
                düzenlemeler doğrultusunda bu bildirimleri kayıt altına
                alacaktır. Dolayısıyla kişisel verilerinizi kullanarak yan etki
                bildirimi hakkında detaylı bilgi almak üzere sizinle iletişime
                geçebilir. Yan etki bildirimlerinizi <b>(0212) 339 10 00</b>{" "}
                numaralı telefondan firmamıza iletebilirsiniz. <br /> <br />
                Bir yan etki bildirimi ile ilişkili olarak <b>Opella</b> ile
                kişisel verilerinizi kendi rızanız ile paylaştığınızda, Opella,
                kişisel verilerinizi üçüncü şahıslarla paylaşmayacaktır. Yasalar
                ile uyumlu olacak şekilde kişisel verilerinizi Sağlık Bakanlığı
                ve <b>Opella </b> adına bu sayfanın yönetiminden sorumlu olan
                anlaşmalı firma ile paylaşılabilir. Kişisel sağlık sorularınız
                ile ilgili doktorunuza danışmanızı tavsiye ederiz.
              </p>
            </div>
            <div className={styles.bottomHolder}>
              <form
                className="registration-wrapper"
                onSubmit={handleSubmit}
                noValidate
              >
                <span className={classNames("bold blue", styles.spanItem)}>
                  Mesleğiniz:
                </span>
                <div className={classNames("flexbox", styles.formItemHolder)}>
                  <div>
                    <input
                      type="radio"
                      id="healthMember"
                      name="radio-group"
                      checked={contact.isHealthMember}
                      onChange={handleChange("isHealthMember")}
                      value="on"
                    />
                    <label htmlFor="healthMember">
                      Sağlık Mesleği Mensubuyum
                    </label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      id="notHealthMember"
                      name="radio-group"
                      checked={!contact.isHealthMember}
                      onChange={handleChange("isHealthMember")}
                      value="off"
                    />
                    <label htmlFor="notHealthMember">
                      Sağlık Mesleği Mensubu Değilim
                    </label>
                  </div>
                </div>
                <div className={classNames("flexbox", styles.formItemHolder)}>
                  <div>
                    <span className={classNames("bold blue", styles.spanItem)}>
                      Ad: *
                    </span>
                    <input
                      type="text"
                      name="name"
                      value={contact.name || ""}
                      onChange={handleChange("name")}
                      className={errors.name}
                    />
                  </div>
                  <div>
                    <span className={classNames("bold blue", styles.spanItem)}>
                      Soyad: *
                    </span>
                    <input
                      type="text"
                      name="surname"
                      value={contact.surname || ""}
                      onChange={handleChange("surname")}
                      className={errors.surname}
                    />
                  </div>
                </div>
                <div className={classNames("flexbox", styles.formItemHolder)}>
                  <div>
                    <span className={classNames("bold blue", styles.spanItem)}>
                      E-posta Adresiniz: *
                    </span>
                    <input
                      type="email"
                      name="email"
                      value={contact.email || ""}
                      onChange={handleChange("email")}
                      className={errors.email}
                    />
                  </div>
                  <div>
                    <span className={classNames("bold blue", styles.spanItem)}>
                      Telefon numaranız:
                    </span>
                    <input
                      type="tel"
                      name="phone"
                      pattern="[0-9]{3}-[0-9]{3}-[0-9]{2}-[0-9]{2}"
                      value={contact.phone || ""}
                      onChange={handleChange("phone")}
                      className={errors.phone}
                    />
                  </div>
                </div>
                <div className={classNames("flexbox", styles.formItemHolder)}>
                  <div>
                    <span className={classNames("bold blue", styles.spanItem)}>
                      Mesajınız: *
                    </span>
                    <textarea
                      onChange={handleChange("note")}
                      value={contact.note || ""}
                      name="note"
                      className={errors.note}
                    />
                  </div>
                </div>
                <div className={classNames("flexbox", styles.terms)}>
                  <input
                    type="checkbox"
                    id="terms"
                    name="terms"
                    value="on"
                    checked={contact.terms}
                    onChange={handleChange("terms")}
                    className={errors.terms}
                  />
                  <label
                    htmlFor="terms"
                    className={classNames("blue", errors.terms)}
                  >
                    <a
                      href={"/gizlilik-politikasi-ve-kvkk-aydinlatma-metni/"}
                      target="_blank"
                    >
                      Gizlilik Politikası ve Kişisel Verilerin Korunması Kanunu
                      Aydınlatma Metni’ ni okuduğumu ve bu kapsamda Opella’nın
                      kişisel verilerimi işlemesini kabul ediyorum.
                    </a>
                  </label>
                </div>
                <p className={styles.paragraph}>
                  Aşağıda belirttiğim iletişim kanaları aracılığıyla bana
                  yönelik her nevi tanıtım etkinlikleri ile ilgili duyuru ve
                  bilgilendirmelerin gönderilmesi amacı ile tarafımla iletişim
                  kurulmasına ve elektronik ileti gönderilmesine rıza veriyorum.
                </p>
                <div className={classNames("flexbox", styles.checkBoxesHolder)}>
                  <div className={classNames("flexbox", styles.checkBoxHolder)}>
                    <input
                      type="checkbox"
                      id="emailContact"
                      name="emailContact"
                      value="on"
                      checked={contact.emailContact}
                      onChange={handleChange("emailContact")}
                    />
                    <label htmlFor="emailContact">E-posta</label>
                  </div>
                  <div className={classNames("flexbox", styles.checkBoxHolder)}>
                    <input
                      type="checkbox"
                      id="smsContact"
                      name="smsContact"
                      value="on"
                      checked={contact.smsContact}
                      onChange={handleChange("smsContact")}
                    />
                    <label htmlFor="smsContact">SMS</label>
                  </div>
                  <div className={classNames("flexbox", styles.checkBoxHolder)}>
                    <input
                      type="checkbox"
                      id="phoneContact"
                      name="phoneContact"
                      value="on"
                      checked={contact.phoneContact}
                      onChange={handleChange("phoneContact")}
                    />
                    <label htmlFor="phoneContact">Telefon</label>
                  </div>
                </div>
                <div className={styles.buttonHolder}>
                  <Button
                    type={ButtonTypesEnum.navy}
                    className="submit"
                    isForm={true}
                    to={"#"}
                  >
                    Gönder
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </Container>
      </Container>
    </Layout>
  );
};

export default ContactPage;
